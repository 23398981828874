import { template as template_f9ef8840aee84db481d0b649c53700ad } from "@ember/template-compiler";
export default template_f9ef8840aee84db481d0b649c53700ad(`
  <li class='row'>
    <span class='row__label'>
      {{@label}}
    </span>
    <span class='row__value'>
      {{@value}}
    </span>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
