import { template as template_378b42b2613d4023b0d44ec7244d5268 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_378b42b2613d4023b0d44ec7244d5268(`
  <header class='login__header'>
    <img src='/pix-certif-logo.svg' alt='Pix Certif' />

    <h1 class='page-title'>{{t 'pages.login.title'}}</h1>

    <p class='login-header__information'>
      {{t 'pages.login.accessible-to'}}
    </p>

    {{#if @hasInvitationAlreadyBeenAccepted}}
      <p class='login-header__invitation-error'>{{t 'pages.login.errors.invitation-already-accepted' htmlSafe=true}}</p>
    {{/if}}

    {{#if @isInvitationCancelled}}
      <p class='login-header__invitation-error'>{{t 'pages.login.errors.invitation-was-cancelled' htmlSafe=true}}</p>
    {{/if}}
  </header>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
